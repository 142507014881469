$background-color: $white !default;
$highlight-color: #ffff00 !default;
$content-padding: 15px !default;

.document-outline {
  padding-bottom: $content-padding;
  display: flex;
  flex-direction: column;
  align-items: center;

  .preview-page-wrapper {
    display: inline-block;
    cursor: pointer;
    position: relative;

    .preview-page-label {
      @include full-width;

      background-color: darken($background-color, 3);
      text-align: center;
      z-index: 1;
      padding: 5px 0;
      border-top: 1px solid darken($background-color, 13);
      font-size: $font-size-base * 0.8;
      font-weight: bold;
    }
  }
}

.document-viewer {
  height: 100%;
  overflow: auto;
  background-color: #e6e6e6;
}

@mixin property-value() {
  color: $base-color;
  font-size: $font-size-base * 0.9;
}

.document-properties {
  position: relative;
  height: 100%;

  .property {
    margin-bottom: 25px;

    & > div {
      @include property-value;
    }
  }

  @include media-breakpoint-up('md') {
    .location-badge {
      @include full-width;
    }
  }
}

.location-badge {
  border: 1px solid $accent-color;
  padding: $content-padding;
  position: relative;
  padding-bottom: 36px;

  & > p {
    @include property-value;
  }

  & > button.location-badge-action {
    @include full-width;
  }
}

.location-modal {
  .location-modal-icon {
    color: $base-color;
    font-size: 82px;
    text-align: center;
    line-height: 82px;
  }
}

.document-search {
  .document-search-result {
    color: #888;
    font-size: $font-size-base * 0.8;
    cursor: pointer;
    padding-top: $content-padding;

    &:hover {
      color: #000;
    }

    .highlight {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($highlight-color, 0.52);
      }

      .highlight-content {
        position: relative;
        background-color: rgba($highlight-color, 0.52);
      }
    }

    &:not(:last-child) {
      padding-bottom: $content-padding;
      padding-top: $content-padding;
      margin: 0;
      border-bottom: 1px solid #eee;
    }
  }
}

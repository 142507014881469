@import './variables.scss';

@import 'react-toastify/scss/main.scss';
// @import 'react-input-range/src/scss/index.scss';
@import './bootstrap.scss';

@import './document.scss';
@import './form.scss';
@import './layout.scss';
@import './repository.scss';
@import './dashboard.scss';
@import './entities.scss';
@import './chat-window.scss';

#root {
  height: 100%;
}

.cosmos-logo {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.text-complementary {
  color: $complementary-color;
}

.notifications-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  // padding: 15px;

  & > div {
    margin-bottom: $spacer;
    margin-right: $spacer;
  }
}

$charts: ('pie', 'bar');
@each $type in $charts {
  .#{$type}-chart {
    svg {
      overflow: visible !important;
    }
  }
}

@mixin circle($color) {
  background: radial-gradient(lighten($color, 25%), $color);
  border: 1px solid darken($color, 5%);
  box-shadow: 0px 0px 3px darken($color, 5%);
}

.circle {
  @include border-radius(50px);
  width: 20px;
  height: 20px;
  @include circle(#ddd);

  $circles: (
    'NRD': #ddd,
    'DRA': #ddd,
    'IFR': #89c4f4,
    'CRD': #038aff,
    'STU': #fff,
    'IFA': #ff9914,
    'ACD': #2ecc71,
    'NAD': #e74c3c,
    'PAD': #f39c12,
    'ELP': #666,
    'SUP': #ccc,
    // new
    'IFD': #252958,
    'IFI': #0cce6b,
    'PRA': #e56399,
    'IFT': #0cce6b,
    'IFC': #e74c3c,
    'CAN': #e8ae68,
    //basic
    'ongoing': #ff9914,
    'nosignoff': #ff0028,
    'signoff': #0cce6b,
  );

  @each $name, $color in $circles {
    &.circle-#{$name} {
      @include circle($color);
    }
  }
}

.close-icon,
.modal-header .close {
  background-color: transparent;
  border: 0;
  text-shadow: none;
  color: $accent-color;
  font-size: 56px;
  line-height: 20px;
  font-weight: 100;

  &:hover {
    color: $accent-color;
  }
}

.dropdown-menu {
  .alerts-menu {
    min-width: 350px;
    max-height: 75vh;
    overflow-y: auto;
    padding: 0;

    .dropdown-item {
      color: $base-color;
      padding-top: 10px;
      padding-bottom: 10px;
      white-space: normal;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
}

.repository-table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: $content-padding;
}

.overflow-inherit {
  overflow: inherit;
}

.welcome-screen {
  background-color: $base-color;
  min-height: 100vh;
  padding: 1.5rem 0;

  .card {
    @include box-shadow(0 0 6px 0px darken($base-color, 15));
  }
}

.my-cosmos-floating {
  &.list-group {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;

    .mycosmos-text-lg {
      font-size: 18px;
    }
  }
}

.comment-entry {
  .mention {
    color: $base-color;

    a {
      color: $base-color;
    }
  }
}

.permission-error-wrapper {
  height: 100%;
  overflow: hidden;

  .blured-content {
    filter: blur(3px);
  }
}

.permission-error,
.locked-warning {
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: rgba(#fff, 0.75);
  height: 100%;
  width: 100%;
}

.watermark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watermark {
  position: absolute;
  color: gray;
  opacity: 0.5;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

// small helper classes
.mh-50 {
  max-height: 50%;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.border-2 {
  border-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.max-h-100 {
  max-height: 100%;
}

.d-md-block {
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.d-md-none {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.h-md-100 {
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

// exists in bootstrap 5, still needed for bootstrap 4
.z-2 {
  z-index: 2;
}

.d-xxl-block {
  // media breakpoint up min width 1400px
  @media screen and (min-width: 1400px) {
    display: block !important;
  }
}

.d-xxl-none {
  // media breakpoint up min width 1400px
  @media screen and (min-width: 1400px) {
    display: none !important;
  }
}

$background-color: $white !default;
$border-color: darken($background-color, 33) !default;

@mixin bottom-border() {
  border: none;
  border-bottom: 1px solid $border-color;
  background: none;

  &:focus {
    border-color: $accent-color;
  }
}

@mixin has-error() {
  border-color: $danger;
  background-color: rgba($danger, 0.1);
}

form {
  .input-group {
    width: 100% !important;

    input.form-control {
      @include bottom-border();
      box-shadow: none;

      &[readonly] {
        border: none !important;
      }
    }
  }

  .form-group {
    input.form-control {
      @include bottom-border();
      @include box-shadow(none);

      &.has-error {
        @include has-error;
      }

      &[readonly] {
        border: none !important;
      }
    }

    .form-control {
      &.select-borderless {
        @include bottom-border();
      }
    }

    .field-error {
      font-size: $font-size-base * 0.8;
      color: $danger;
    }
  }
}

.form-property {
  &.highlighted {
    background-color: #ffecb4;
  }
}

.date-range-slider {
  margin-bottom: 15px;
}

.date-range-slider.active {
  .input-range {
    .input-range__label--value {
      color: $base-color;
    }
  }
}

.input-range {
  margin-top: 10px;

  .input-range__label {
    &.input-range__label--min,
    &.input-range__label--max {
      display: none;
    }
  }
}

.input-range__track.input-range__track--background
  span.input-range__slider-container:nth-child(3)
  span.input-range__label.input-range__label--value {
  top: 0.3rem;
}

.repository-filter,
.filter-manager {
  color: $base-color;

  .repository-filter-field,
  .filter-manager-field {
    display: flex;
    align-items: baseline;

    .form-group {
      flex-grow: 1;
    }
  }

  .form-group {
    margin-bottom: 0.5rem;

    @include media-breakpoint-up('md') {
      margin-bottom: 0.3rem;

      .value-picker {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        min-height: auto;
      }
    }

    .depending {
      margin-left: 15px;
    }
  }

  button {
    line-height: 1rem;

    &:active {
      box-shadow: none !important;
    }
  }
}

.repostory-table-wrapper {
  margin-bottom: 15px;
  overflow: auto;
}

.table-allscreen {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;

  @include media-breakpoint-down('sm') {
    height: 100vh !important;
  }

  & > .repository-table-wrapper {
    flex-grow: 1;
    height: 100%;
    .repository-table {
      // height: 100%;
      display: flex;
      flex-direction: column;

      div[role='rowgroup'] {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.repository-table-overflow {
  position: relative;
  .repository-table {
    min-width: auto !important;
    overflow-x: auto;
  }
}

.repository-table[role='table'] {
  div[role='row'] {
    div[role='cell'],
    div[role='columnheader'] {
      &.sticky-column {
        position: sticky !important;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }
    }

    div[role='columnheader'] {
      font-weight: bold;
      color: $base-color;
      border-color: currentcolor $base-color $base-color;

      vertical-align: bottom;
      border-bottom: 2px solid $base-color;
      padding: 0.3rem;
      position: relative;

      .value-picker,
      .field-error {
        font-weight: normal;
        position: initial;
      }

      .resizer {
        border-right: 5px dotted $base-color;
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
      }
    }
  }

  &.editing {
    div[role='row'] {
      div[role='columnheader'] {
        user-select: none;

        .draggable-header {
          height: 100%;
          transition: background-color 0.2s ease-in-out;
          border: 1px solid transparent;

          &:hover {
            background-color: darken($background-color, 7);
            border: 1px dashed $base-color;
          }
        }

        .header-hide-button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  div[role='rowgroup'] {
    border-bottom: 2px solid #1d4477;

    div[role='row'] {
      cursor: default;
      position: relative;

      .table-row-actions {
        position: absolute;
        // top: 100%;
        margin: 15px;
        bottom: 0;
        z-index: 2;
        background-color: #fff;
      }

      &:hover {
        div[role='cell'] {
          background-color: lighten($base-color, 65);
        }
      }

      div[role='cell'] {
        padding: 0.3rem;
        border-top: 1px solid #dee2e6;
        overflow: hidden;
        user-select: none;
        word-break: break-all;

        & > * {
          word-break: initial;
        }

        &.has-error {
          background-color: rgba(red, 0.3);
        }

        &.selected {
          background-color: rgba($accent-color, 0.3);
        }
      }

      &.selected {
        background-color: rgba($base-color, 0.1);
      }

      &.subrow {
        background-color: rgba(rgb(247, 227, 52), 0.1);
      }

      &.has-error {
        background-color: rgba(red, 0.1);
      }

      &.has-warning {
        background-color: rgba(yellow, 0.1);
      }
    }
  }

  &.repository-table-bordered {
    div[role='row'] {
      div[role='columnheader'] {
        &:not(:last-child) {
          border-right: 1px solid #dee2e6;
        }
      }
    }

    div[role='rowgroup'] {
      div[role='row'] {
        div[role='cell'] {
          &:not(:last-child) {
            border-right: 1px solid #dee2e6;
          }
        }
      }
    }
  }

  &.table-sticky {
    & > div:first-child {
      position: sticky;
      top: 0;
      z-index: 3;
      box-shadow: 0 0 10px #757575;
    }

    div[role='row'] {
      div[role='columnheader'] {
        background-color: $background-color;
      }
    }
  }
}

.folder-view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &.list {
    flex-direction: column;

    .folder-view-document {
      padding: 5px 0;

      .folder-view-icon {
        display: inline-block;
        margin-right: 15px;
      }

      &-hoverable {
        &:hover {
          background-color: $light;
          cursor: pointer;
        }
      }
    }
  }

  &:not(.list).folder-view-document {
    min-width: 85px;
    flex-basis: 25%;
    text-align: center;
    overflow: hidden;
    padding: $spacer;
    position: relative;
    flex-grow: 1;

    button.close {
      padding: 3px;
      color: $danger;
      font-size: 2rem;
      position: absolute;
      top: 0;
      right: 0;
    }

    .folder-view-icon {
      font-size: 3rem;
      margin-bottom: $spacer;
    }
  }
}

.multitasking {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: $spacer;

  // &.seamless {
  //   flex-direction: column;
  //   padding: 0;

  //   .multitasking-card {
  //     border: none;
  //     // margin: 0 !important;
  //     margin: 0 auto !important;
  //   }
  // }
}

.multitasking-card {
  flex-basis: 40vw;
  flex-shrink: 0;
  flex-grow: 1;

  height: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 5px;
  border: 1px solid $dark;

  transition: flex-basis 0.2s ease-in-out;
  width: 100%;

  & ~ .multitasking-card {
    margin-left: $spacer;
  }

  .multitasking-card-header {
    padding: $spacer;
    display: flex;
    align-items: baseline;
    color: $dark;
  }

  &.collapsed {
    flex-basis: 50px;
    flex-grow: 0;
    overflow: hidden;
    cursor: pointer;

    & > *:not(.multitasking-card-header) {
      display: none;
    }

    .multitasking-card-header {
      & > *:not(.card-header-identifier) {
        display: none;
      }
    }
  }
}

$accent-color: #3ed8c3; // #3fd8c3;
$base-color: #1d4477;
$secondary-color: #1d447755;
$white: #fff;
$background-color: $white;
$highlight-color: #ffcc00;
$gutter-width: 30px;
$content-padding: calc($gutter-width / 2);
$font-size-base: 1rem;
$complementary-color: #ef7d00;

$input-range-slider-background: $accent-color;
$input-range-slider-border: $accent-color;
$input-range-track-active-background: $accent-color;
$input-range-font-family: 'Segoe UI';

@mixin sidebar() {
  @include box-shadow(0px 0px 8px 0px darken($background-color, 18));
  background-color: darken($background-color, 2);
  min-height: 100%;
  padding-top: $content-padding;
  padding-bottom: $content-padding;
}

@mixin full-width($position: absolute, $sticky: bottom) {
  position: $position;
  #{$sticky}: 0;
  left: 0;
  width: 100%;
}

$navbar-items-margin: 0.5rem !default;
$header-height: 35px !default;
$footer-height: 55px !default;
$content-padding: 15px !default;

.navbar,
.navbar-default {
  .dropdown-toggle {
    &:after {
      vertical-align: middle;
      display: none;
    }
  }

  .navbar-toggler {
    border-color: $accent-color;
    color: $accent-color;
    border-width: 2px;
    @include border-radius;

    span {
      color: $accent-color;
    }

    &:focus {
      border-color: $accent-color;
    }
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-brand {
    color: $base-color;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: $base-color;
    }
  }

  .navbar-nav {
    & > button {
      text-align: left;
      // display: flex;
      // align-items: baseline;
      // white-space: nowrap;
    }
  }

  @include media-breakpoint-up('md') {
    height: 45px;
    z-index: 1;

    &.navbar-expand {
      margin: auto;

      &.block-weird-stuff {
        width: auto !important;
        border-radius: 0 !important;
        margin: 0 !important;
        border: none !important;
      }
    }

    &.navbar-main {
      height: $header-height;
      z-index: 3;
    }

    .navbar-nav {
      align-items: center;

      & > .btn,
      & > .input-group {
        &:not(:last-child) {
          margin-right: $navbar-items-margin;
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    &.navbar-expand {
      .navbar-brand {
        font-size: 1.125rem;
      }
    }
  }

  @include media-breakpoint-down('sm') {
    min-height: 60px;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;

    .search-bar-wrapper {
      opacity: 1 !important;
      transform: none !important;
    }

    .context-menu-icon {
      float: right;
      margin-top: 3px;
    }

    .navbar-nav {
      margin-top: $navbar-items-margin;

      & > .btn,
      & > .input-group {
        &:not(:last-child) {
          margin-bottom: $navbar-items-margin;
        }
      }
    }
  }
}

.footer {
  @include full-width(fixed);

  height: $footer-height;
  color: $white;
  z-index: 3;

  padding-top: $content-padding;

  a {
    &,
    &:hover,
    &:active,
    &:visited {
      color: white;
    }
  }
}

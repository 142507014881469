@mixin appPage() {
  background-color: $white;
  min-height: 100%;
  padding-top: $content-padding;
}

.filter-folder-page {
  display: flex;
  flex-direction: column;
}

.filter-folder {
  flex-grow: 1;

  & > .row {
    height: 100%;
  }

  h1 {
    margin-bottom: 24px;
  }

  .filter-folder-sidebar {
    @include sidebar();

    .property {
      color: lighten(#000, 20%);
      b {
        color: $base-color;
      }
    }
  }
}

.filter-folders-list {
  & > div {
    margin: 0 -15px;
    padding: 7px 15px;

    &:hover,
    &.active {
      background-color: rgba($base-color, 0.2);
    }

    &.empty {
      color: lighten(#000, 75);
      display: none;
    }
  }
}

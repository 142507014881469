$background-color: $white !default;
$content-padding: 15px !default;

.landing-page {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 15px;
  // background: url('../images/windpark.jpg');
  background-size: cover;

  @include media-breakpoint-down('sm') {
    height: calc(100vh - 95px) !important;
  }

  & > div {
    height: 150vh;
    position: relative;
  }

  .center-circle {
    position: absolute;
    margin-top: 0px;
    width: 100px;
    height: 100px;
    background: white;
    left: 50%;
    top: 50vh;
    border-radius: 50px;
  }

  .search-section {
    position: absolute;
    height: calc(100vh - 95px);
    padding-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // align-items: center;

    & > .search-wrapper {
      // width: 35vw;
      // height: 40vh;
    }
  }

  .explorer-section {
    height: calc(100vh - 60px);
    position: absolute;

    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width: 100%;

    .explorer {
      // width: 70vw;
      height: 70vh;
      background-color: $background-color;
      // padding-top: 15px;
      overflow: hidden;
      padding: 15px;
    }
  }
}

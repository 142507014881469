.chat-window {
  height: 100%;

  & > .row {
    height: 100%;

    .chat-sidebar {
      height: 100%;
      border-right: 1px solid #ddd;
    }

    .chat-entities-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .chat-entity-item {
        padding: 15px;

        & ~ .chat-entity-item {
          border-top: 1px solid #ddd;
        }

        &.active {
          background-color: darken($background-color, 10);
        }
      }

      .inbox_chat {
        height: 100%;
        overflow-y: auto;
      }
    }

    .chat-contents {
      position: relative;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 10vh;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        pointer-events: none;
      }
    }

    .chat-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;

      .chat-messages {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

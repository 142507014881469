$grid-gutter-width: $gutter-width;
$primary: $accent-color;
$dark: $base-color;
$secondary: $secondary-color;
$secondary-btn-color: $base-color;

$headings-color: $base-color;

$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: 0;

$input-bg: $background-color;
$input-border-radius: 0;
$input-focus-box-shadow: none;

$btn-border-width: 0;
$btn-border-radius: 0;

$input-color: $base-color;

$dropdown-link-active-bg: $accent-color;

$card-bg: $background-color;

$pagination-color: $white;
$pagination-bg: $accent-color;
$pagination-border-width: 0;
$pagination-hover-bg: $base-color;
$pagination-hover-color: $white;
$pagination-active-bg: $base-color;
$pagination-disabled-bg: transparent;

$list-group-bg: $background-color;

$enable-rounded: true;
$enable-shadows: true;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: false;

$modal-content-border-radius: 0;

$danger: #e7343f;
$warning: #ffcc00;
$success: #5ab88f;

@import 'bootstrap/scss/bootstrap.scss';

.btn {
  white-space: nowrap;

  &.btn-primary {
    @include button-variant(
      $accent-color,
      #000,
      $base-color,
      #000,
      $base-color
    );
    color: $white;
  }

  &.btn-outline {
    @include button-outline-variant(
      $secondary-btn-color,
      $secondary-btn-color,
      transparent
    );

    &.active {
      background-color: $secondary-btn-color !important;
      color: black !important;
      box-shadow: none !important;
    }
    font-weight: bold;
    border: 2px solid;
  }

  &.btn-link-dark {
    color: $secondary-btn-color;
  }

  &.btn-lg {
    @include button-size(6px, 45px, $font-size-base, 1.5, 0);
  }

  &.btn-sm {
    @include button-size(0.1rem, 0.5rem, $font-size-base, 1.5, 0.2rem);
  }
}

h6 {
  font-weight: bold;
}

.table {
  tbody {
    tr.selected {
      background-color: rgba($base-color, 0.1);
    }
  }

  &.table-selectable {
    tbody {
      tr {
        cursor: pointer;
        user-select: none;
      }
    }
  }

  &.table-sticky {
    border-collapse: separate;
    border-spacing: 0;

    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          background-color: $background-color;
        }
      }
    }
  }

  thead {
    tr {
      th {
        color: $base-color;
        border-top: none;
        border-color: $base-color;
      }
    }
  }

  tbody {
    @include table-row-variant(
      'danger',
      lighten($danger, 40%),
      lighten($danger, 35%)
    );

    tr {
      &.table-danger {
        color: $danger;
      }
    }
  }
}

.nav.nav-pills {
  .nav-item {
    .nav-link {
      border: 1px solid transparent;
      &.active {
        border: 1px solid $accent-color;
        color: $accent-color;
        background: none;
      }
    }
  }
}

.border-transparent {
  border-color: transparent !important;
}

// Set the number input field without arrows
// For Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// For Firefox
input[type='number'] {
  -moz-appearance: textfield;
}
